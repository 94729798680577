import React from 'react'
import Layout from '../../../layouts'
import SEO from '../../../components/elements/seo'

const LiveChat = () => {
  return (
    <Layout isSubMenuOpen={false}>
      {/* ---- PAGE SEO SETTINGS ---- */}
      <SEO
        title="The Claxton Bank - Banking, Credit Cards, Loans, and More"
        description="The Claxton Bank is the premier community bank in Evans, Chatham, Bryan, and surrounding counties.  Enjoy a small-town feel, big-time capabilities, and the caring and knowledgeable staff you deserve."
        location="/"
        ogImg='/images/social-graph/the-claxton-bank.jpg'
      />
      <h1>Live Chat Page</h1>
    </Layout>
  )
}

export default LiveChat